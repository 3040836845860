<template>
	<div class="activity_detail">
		
		
		<div class="home_center" v-if="eventInfo">
			
			<div>
				<div class="banner">
					<el-carousel indicator-position="outside">
						<el-carousel-item v-for="item in eventInfo.showpics" :key="item">
						  <!-- <h3>{{ item }}</h3> -->
						  <el-image
								style="width: 100%; height: 343px"
								:src="item"
								fit="cover"></el-image>
						</el-carousel-item>
					  </el-carousel>
				</div>
				
				<div class="center_left_box" v-loading="loading"  element-loading-background="rgba(0, 0, 0, 0.8)">
					
					<div class="center_left">
						
						<div class="left_tui">
							<div class="tui_title">
								<!-- 活动信息 -->{{$t('activity.HDXX')}}
							</div>
							<div class="tui_box">
								<div class="tui_list">
									<!-- 活动名称 -->{{$t('add_activity.HDMC')}}：{{eventInfo.event_name}}
								</div>
								<div class="tui_list">
									<!-- 发起者 -->{{$t('activity.FQZ')}}：{{eventInfo.open_name}}
								</div>
								<div class="tui_list">
									<!-- 发起时间 -->{{$t('activity.FQSJ')}}：{{$util.rTime(eventInfo.create_time)}}
								</div>
								<div class="tui_list">
									<div><!-- 活动时间 -->{{$t('activity.HDSJ')}}：</div>
									<div>
										<div>{{eventInfo.event_time_zone}}</div>
										<div><!-- 始 -->{{$t('START')}}：{{$util.rTime(eventInfo.event_start_time)}}</div>
										<div><!-- 终 -->{{$t('END')}}：{{$util.rTime(eventInfo.event_end_time)}}</div>
									</div>
								</div>
								<div class="tui_list">
									<!-- 集合地点 -->{{$t('activity.JHDD')}}：{{$util.getUnitVal(eventType,eventInfo.event_type_method)}}
								</div>
								<div class="tui_list" v-if="eventInfo.event_type_method==1">
									<!-- 集合地点 -->{{$t('ADR')}}：{{eventInfo.event_location}}
								</div>
								<div class="tui_list" v-if="eventInfo.event_type_method==0">
									<!-- 活动链接 -->{{$t('HDLJ')}}：{{eventInfo.online_other_links}}
								</div>
								<div class="tui_list">
									<!-- 活动人数 -->{{$t('activity.HDRS')}}：{{eventInfo.event_num_people}}{{$t('REN')}}
								</div>
								<div class="tui_list">
									<!-- 参加限制 -->{{$t('activity.CJXZ')}}：{{eventInfo.event_type_method==0?$t('add_activity.WXZ'):$t('add_activity.XSH')}}
								</div>
								
								<el-button type="primary" class="tui_btn" disabled style="background-color: #aaa;border-color: #aaa;color:#fff;" v-if="eventInfo.event_status==4">{{$t('HDYJS')}}</el-button>
								<el-button type="primary" class="tui_btn" disabled style="background-color: #aaa;border-color: #aaa;color:#fff;" v-else-if="eventInfo.event_num_people==eventInfo.join_num_people">{{$t('BMRSYM')}}</el-button>
								<template v-else>
									<el-button type="primary" class="tui_btn" @click="join" v-if="eventInfo.is_join==0">{{$t('activity.CJ')}}</el-button>
									<el-button type="primary" class="tui_btn" style="background-color: #fab269;border-color:#fab269;color:#fff"  v-else>{{$t('YCJ')}}</el-button>
								</template>
								
								
								
							</div>
							<el-button type="primary" class="tui_change" @click="collect">
								<img src="../../assets/img/like_fill.png" style="width: 25px;height: 25px;" alt="" v-if="eventInfo.is_collect==1">
								<img src="../../assets/img/like2.png" style="width: 25px;height: 25px;" alt="" v-else>
							</el-button>
							
						</div>
						<div class="left_tui">
							<div class="tui_title">
								<!-- 活动信息 -->{{$t('activity.HDGY')}}
							</div>
							<div class="tui_box">
								<div class="tui_list">
									{{eventInfo.event_desc}}					
								</div>
								
							</div>
							
						</div>
					</div>
					
					<div class="center_people" >
						<div class="people_title">
							{{eventInfo.event_name}}
						</div>
						<div class="people_html" v-html="eventInfo.event_details">
							
						</div>
					</div>
				</div>
				
				
			</div>


			
			
			
			
			
			<!-- 消息列表 -->
			<Homeright></Homeright>
		</div>
		<!-- 回复弹窗 -->
		<!-- <Reply></Reply> -->
		<!-- 全部评论 -->
		<!-- <Pinlun></Pinlun> -->
	</div>
</template>

<script>
	import Homeright from '../../components/home/home_right.vue';
	export default {
		components: {
			Homeright,
		},
		data() {
			return {
				event_id:'',
				eventInfo:null,
				loading:false,
				eventType:[]
			};
		},
		
		created(){
			this.getUnit();
			if(this.$route.query.event_id){
				this.event_id=this.$route.query.event_id;
				this.getDetail();
			}
			
		},
		methods:{
			//获取字典数据
			async getUnit(){
				let res=await this.$request.get('/api/sysdict/getDictList');
				this.eventType=res.data.event_type_method.data;
				
				// this.eventType.unshift({data_code:'',data_value:'全部'});
				// this.eventSort.unshift({data_code:'',data_value:'默认'});
			},
			//参加活动
			async join(){
				let res=await this.$request.get('/api/event/joinEvent',{event_id:this.event_id});	
				// this.$message({
				// 	message:res.message,
				// 	type:'success'
				// });
				this.eventInfo.is_join=this.eventInfo.is_join==0?1:0;
			},
			//收藏
			async collect(){
				await this.$request.get('/api/event/collectEvent',{event_id:this.event_id});
				this.eventInfo.is_collect=this.eventInfo.is_collect==0?1:0;
			},
			async getDetail(){
				this.loading=true;
				let res=await this.$request.get('/api/event/getEvent',{event_id:this.event_id});
				this.loading=false;
				this.eventInfo=res.data;
				
			},
		}
	};
</script>

<style lang="less" scoped>
	.activity_detail{
		min-height: 100%;
		background-color: #212121;
		.detail_list{
			width: 1190px;
			margin: 0 auto;
		}
		.center_left {
			width: 286px;
			min-height: 233px;
			// background: #212121;
			border-radius: 3px;
			color:#fff;
			// border: 2px solid #567BB6;
			// padding: 30px;
			// box-sizing: border-box;
			.btn{
				width: 286px;
				height: 51px;
				background: #212121;
				border-radius: 7px;
				border: 1px solid #567BB6;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				box-sizing: border-box;
				margin-bottom: 10px;
			}
			.btn:hover{
				width: 286px;
				height: 51px;
				background: #567BB6;
				border-radius: 7px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: none;
				color: #fff;
				margin-bottom: 10px;
			}
			.active{
				width: 286px;
				height: 51px;
				background: #567BB6;
				border-radius: 7px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: none;
				color: #fff;
				margin-bottom: 10px;
			}
			.el-button{
				margin-left: 0;
			}
			.left_user{
				width: 286px;
				min-height: 169px;
				border-radius: 7px;
				border: 1px solid #567BB6;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 20px;
				.user_img{
					width: 103px;
					height: 103px;
					margin-right: 25px;
					border-radius: 10px;
				}
				.user_right{
					width: 100px;
					.right_name{
						font-size: 15px;
						font-weight: bold;
						margin-bottom: 10px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
					.right_msg{
						font-size: 12px;
						margin-bottom: 10px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}
			.left_tui{
				font-size: 15px;
				padding: 30px;
				background-color: #567BB6;
				border-radius: 3px;
				margin-bottom: 30px;
				.tui_title{
					font-weight: bold;
					margin-bottom: 10px;
				}
				.tui_box{
					padding: 20px 0;
					border-top: 1px solid #FFFFFF;
					line-height: 1.3;
					.tui_list{
						display: flex;align-items: flex-start;
						font-size: 12px;
						margin-bottom: 5px;
					}
					.tui_btn{
						width: 208px;
						height: 34px;
						background: #FFFFFF;
						border-radius: 3px;
						border: 1px solid #FFFFFF;
						display: flex;
						align-items: center;
						justify-content: center;
						color: #212121;
						margin-top: 10px;
					}
				}
				.tui_change{
					width: 208px;
					height: 34px;
					border-radius: 3px;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: #567BB6;
					border: 1px solid #FFFFFF;
				}
				
			}
			
		}

		.home_center {
			display: flex;
			justify-content: flex-end;
		}
		.banner{
			width: 1180px;
		}
		.center_left_box{
			display: flex;
		}
		.center_people {
			width: 878px;
			padding: 20px;
			box-sizing: border-box;
			
			background-color: #000000;
			color: #fff;
			margin: 0 20px;
			.people_title{
				width: 100%;
				height: 40px;
				line-height: 40px;
				border-bottom: 1px solid #567BB6;
			}
			.people_html{
				padding: 20px;
				line-height: 1.3;
				
			}
			

		}
	}
</style>
